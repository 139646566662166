import React, { useEffect } from 'react';
import { useState } from 'react';
import { Table } from '../components/Table';
import { HeaderBar } from '../components/HeaderBar'


export const Database = () => {
  const [ pageNumber, setPageNumber ] = useState(1);
  const [ headers, setHeaders ] = useState([]);
  const [ rows, setRows ] = useState([]);
  const MAX_PAGE_NUMBER = 100;
  const ROWS_PER_PAGE = 13;


    const pageBack = () => {
      if(pageNumber > 1){
        setPageNumber(pageNumber - 1);
        //load page
      }
    }
    const pageForwards = () => {
      if(pageNumber < MAX_PAGE_NUMBER){
        setPageNumber(pageNumber + 1);
      }
    }

    useEffect(() => {
      fetch(`https://purificationdatabase.herokuapp.com/main?offset=${(pageNumber-1)*ROWS_PER_PAGE}&rowsPerPage=${ROWS_PER_PAGE}`).then((res) => {
        res.json().then((responseData) => {
          setHeaders(responseData.headers);
          setRows(responseData.rows);
        });
        
      })
    }, [pageNumber]);

  return (
    <div>
        <HeaderBar currentPage={"Database"}></HeaderBar>
        <div className="table-container">
          <Table headers={headers} rows={rows}></Table>
        </div>
        <div className="button-container">
          <button id="prevBtn" onClick={pageBack}>Previous</button>
          <span id="pageNumber">{pageNumber}</span>
          <button id="nextBtn" onClick={pageForwards}>Next</button>
        </div>
    </div>
  )
}
