import React from 'react'
import { HeaderBar } from '../components/HeaderBar'

export const Home = () => {
  return (
    <div className='home'>
      <HeaderBar currentPage={"Home"}></HeaderBar>
        <div className="main">
          <div className="index-top-row">
              <h2>About</h2>
              <p>Protein purification is a critical step for structural studies, proteomics, and drug discovery. To perform a 
                successful purification, one must carefully choose the conditions that preserve protein structure and function.
                <br /> 
                <br />
                PurificationDB is a curated database with 4,732 entries of buffer conditions for protein purification using 
                size-exclusion, ion-exchange and affinity chromatography derived from literature. The derived buffers were 
                used in the preparation procedures for crystallization that yielded protein structures deposited in the 
                Protein Data Bank, which means they are proven to be reliable and suitable for proteins of interest. For each
                 entry in the database, we specify the type of the chromatography used and the conditions including pH, 
                 concentrations of salts, buffering agents, detergents and other additives. Furthermore, the database entries 
                 are mapped to their corresponding PDB and UniProt identifiers along with protein sequence.
                 <br />
                 <br />
                In addtion to browsing through the entire collection of conditions, this web-interface allows users to search 
                for entries by protein name, sequence or UniProt ID to easily access conditions for their protein of interest.
                 We developed this database to make information more accessible to researchers, to ultimately contribute to the 
                 growing effort of creating resources that organize experimental conditions and data for improved access and 
                 analysis.
            </p>
          </div>
          <div className="index-middle-row">
              <h2>Contact</h2>
              <p>
                <strong>Dr. Nada Lallous</strong><br /> (nlallous@prostatecentre.com) <br />
                <strong>Dr. Artem Cherkasov</strong> <br />(acherkasov@prostatecentre.com) <br />
                <br />Vancouver Prostate Centre, University of British Columbia, 2660 Oak Street, Vancouver, 
                BC V6H 3Z6, Canada
              </p>
          </div>
        </div>
    </div>
  )
}
